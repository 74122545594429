<template>
    <div>
        <div class="loading">
            <div class="loading-title disCenter">
                <img src="../assets/checkTitleIcon.png" alt="">
                <span class="marLeft10 font16 color0088FF">
                    审核详情
                </span>
            </div>
           <div class="bgFF content">
               <div class="">
                   <div style="display: flex;">
                       <img style="width: 109px;height: 109px;" :src="preFix+ userList.goods.img" alt="">
                       <div class="marLeft16 font14 color78 marTop8" style="flex: 1;display: flex;flex-direction: column;">
                        <span style="display: inline;margin-right: 10px;margin-bottom: 15px">
                            商品名称:  {{userList.goods.title}}
                        </span>
                           <span>任务数量:  {{userList.nums}}</span>
                       </div>
                   </div>
                   <div class="">

                   </div>
               </div>
               <div class="marTop40">
                   <div class="disCenter table-tr colorFF font18 ">
                       <div class="flex1 textCenter">
                        <span>
                            视频ID
                        </span>
                       </div>
                       <div class="flex1 textCenter">
                           状态
                       </div>
                       <div class="flex1 textCenter">
                           视频
                       </div>
                       <div class="flex2 textCenter">
                           操作
                       </div>
                   </div>
                   <div class="disCenter table-td  font18 " v-for="(item,index) in fileListArres"  v-if="(index >= (pageIndex-1) * 10) && index < pageIndex * 10 ">
                       <div class="flex1 textCenter">
                        <span class="color69">
                            {{item.id}}
                        </span>
                       </div>
                       <div class="flex1 textCenter cursor" :class="item.status == 2 ? 'color69' : 'color0D9'">
                           <p v-if="item.status != 2">
                               {{item.status == 1 ? '待审核' : item.status == 3 ? '通过' : '拒绝'}}
                           </p>
                           <el-tooltip class="item" effect="dark" :content="item.reason" placement="top" v-if="item.status == 2">
                              <p>
                                  {{item.status == 1 ? '待审核' : item.status == 3 ? '通过' : '拒绝'}}
                              </p>
                           </el-tooltip>
                       </div>
                       <div class="flex1 textCenter dis justify-center">
                           <div class="video" @click="videoPupopFn(item.video_src)" :class="item.status == 2 ? 'bgC2' : 'bg0D9'">
                               <img src="../assets/videoIcon.png" alt="">
                               <span class="colorFF">
                                视频
                            </span>
                           </div>
                       </div>
                       <div class="flex2 dis justify-center">
                           <div style="display: flex" v-if="item.status == 2" >
                               <el-upload
                                       :action="action"
                                       :show-file-list="false"
                                       :file-list="fileList"
                                       accept=".mp4"
                                       :auto-upload="false"
                                       :on-change="onChangeFn"
                               >
                                   <el-button size="small" type="primary" class="afresh">重新上传</el-button>
                               </el-upload>
                               <el-button class="elBtn" slot="trigger" size="small" type="danger" @click="again(item.id)">保存
                               </el-button>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
<!--            <el-main>-->
<!--                <el-table :data="fileListArres"  max-height="500">-->
<!--                    <el-table-column prop="id" label="ID" width="100">-->
<!--                    </el-table-column>-->
<!--                    <el-table-column prop="status" label="状态" width="100">-->
<!--                        <template slot-scope="scope">-->
<!--                            <div style="display: flex;justify-content: center">-->
<!--                                <p style="color: red" v-if="scope.row.status == 1">待审核</p>-->
<!--                                <p style="color: green" v-if="scope.row.status == 3">通过</p>-->
<!--                                <el-tooltip class="item" effect="dark" :content="scope.row.reason" placement="top-end">-->
<!--                                    <p style=" cursor:pointer;color: white;display: inline-block;padding: 3px 10px;background-color:#F56C6C; font-size: 12px;border-radius: 12px" v-if="scope.row.status == 2">拒绝</p>-->
<!--                                </el-tooltip>-->
<!--                            </div>-->
<!--                        </template>-->
<!--                    </el-table-column>-->
<!--&lt;!&ndash;                    <el-table-column prop="reason" label="拒绝原因" width="200"></el-table-column>&ndash;&gt;-->
<!--                    <el-table-column prop="video" label="视频" width="200">-->
<!--                        <template slot-scope="scope">-->
<!--                            <div>-->
<!--                                <img style="width: 30px;height: 30px;cursor:pointer;" src="../assets/videoTwo.png" @click="videoPupopFn(scope.row.video_src)">-->
<!--                            </div>-->
<!--                        </template>-->
<!--                    </el-table-column>-->
<!--                    <el-table-column prop="address" label="操作" width="200">-->
<!--                        <template slot-scope="scope">-->

<!--                        </template>-->
<!--                    </el-table-column>-->
<!--                </el-table>-->
<!--            </el-main>-->
        </div>
<!--        上传加载-->
        <el-pagination
                style="display: flex;justify-content: center;margin-top: 20px"
                background
                layout="prev, pager, next"
                :current-page="pageIndex"
                @current-change="sizeChange"
                :total="fileListArres.length">
        </el-pagination>
        <el-button
                style="opacity: 0;"
                v-loading.fullscreen.lock="fullscreenLoading"
                element-loading-text="拼命上传中"
        >
        </el-button>
<!--        播放视频-->
        <el-dialog
                title="提示"
                :visible.sync="centerDialogVisible"
                center
                class="Videodialog"
                @close="beforeClose"
        >
            <video id="myVideo" style="width: 100%;max-height: 500px;" :src="preFix + videoSrc" controls :autoplay="autoplay" ></video>
            <span slot="footer" class="dialog-footer">
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import axios from 'axios'
    import api from '../lnit/api'
    export default {
        name: "check",
        data() {
            return {
                pageIndex:1,
                fullscreenLoading: false,
                autoplay:false,
                preFix:null,
                videoSrc:null,
                centerDialogVisible:false,
                formData: null,
                fileListArr: [],
                fileListArres:[],//详情页列表
                saveVideoList:[],
                fileList: [],
                fromStatus: 1,
                formDate:null,
                i: 1,
                uploadIndex: null,
                saveStatus:false,//上传成功后保存视频
                taskId:null,//任务id
                taskStatus:null,
                videoShow:false,
                userList:null,
                action:'',
            }
        },
        created() {
            this.taskId = this.$route.query.id
            this.taskStatus = this.$route.query.status
            this.taskDetailFn()
            this.preFix = api.url_prefix
            this.action = api.url + api.upload_file
        },
        methods: {
            centerDialogVisibleFn(){
              this.centerDialogVisible = false
                myVideo.pause()
              console.log("ss")
            },
            beforeClose(){
                myVideo.pause()
            },
            sizeChange(e){
              this.pageIndex = e
            },
            videoPupopFn(video){//点击播放视频
                this.videoSrc = video
                this.centerDialogVisible = true
                this.autoplay = true
                myVideo.load()
            },
            saveVideoFn(){
                if(!this.saveStatus){
                    return
                }
            },
            taskDetailFn(){
                this.$get({
                    url:api.pk_task_details,
                    data:{
                        id:this.taskId
                    }
                }).then((res)=>{
                    this.fileListArres = res.data.info.videoTasks
                    this.userList = res.data.info
                })
            },
            onChangeFn(file, fileList) {
                this.fileListArr = file
                let size = (file.size/1024/1024).toFixed(1)
                console.log( size)
                if(size > 50){
                    this.$message({
                        message:'请上传不超过50M的视频',
                        type:'error'
                    })
                    return
                }
                this.$forceUpdate()
                this.onProgress()
            },
            onProgress(id) {//上传到服务器
                if (!this.fromStatus) {
                    return
                }

                this.fullscreenLoading = true
                this.videoShow =false
                this.fromStatus = 0
                this.formDate = new FormData()
                // this.formDate.append('file', "12133")
                //let a = this.formDate.get('file')
                this.formDate.set('file', this.fileListArr.raw)
                axios.post( api.url + "/api/common/upload_file", this.formDate, {
                    headers: {
                        "token": localStorage.getItem('token'),
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    this.saveVideoList = res.data.url
                    this.fromStatus = 1
                    this.videoShow = true
                   this.fullscreenLoading = false
                    this.$message({
                        message:'上传成功请重新保存',
                        type:'success'
                    })
                }).catch(res => {

                })
            },
            again(id) {
                // if(!this.videoShow){
                //     this.$message({
                //         message:'上传中',
                //         type:'success'
                //     })
                //     return
                // }

                if(!this.saveVideoList.length){
                    this.$message({
                        message:'请先上传视频再保存',
                        type:'error'
                    })
                    return
                }

                this.$post({
                    url:api.pk_re_save_videos,
                    data:{
                        task_id:this.taskId,
                        video_src:this.saveVideoList,
                        vid:id,
                    }
                }).then((res)=>{
                    if(res.data.code == 0){
                        this.$message({
                            message:'重新保存成功',
                            type:'success'
                        })
                        this.saveVideoList = []
                        this.taskDetailFn()
                    }
                })
            },
        }
    }
</script>

<style scoped  lang="scss">
    td {
        min-width: 200px;
        text-align: center;
        height: 64px;
        line-height: 64px;
    }

    th {
        min-width: 200px;
        text-align: center;
        height: 40px;
        line-height: 40px;
    }
    .table-td{
        height: 67px;
        background-color: #F4FAFF;
        border-bottom: 1px dashed #ADDBFF;
    }
    .video{
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 7px 11px;
        border-radius: 16px;
        cursor: pointer;
        img{
            width: 24px;
            height: 22px;
        }
    }
    td:nth-child(1) {
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .loading {
        padding: 20px;

        .loading-title {
            padding: 19px 0 23px 19px;
            background-color: #fff;
            margin-bottom: 7px;
            img{
                width: 29px;
                height: 29px;
            }
        }
    }
    .content{
        background-color: #fff;
        padding: 18px 20px 20px;
    }
    .el-upload-list .el-upload-list--text {
        border: 2px solid;
    }
    .table-tr{
        height: 53px;
        background-color: #0D96FF;
    }
    .fileList {
        width: 100%;

        .fileList-tr {
            width: 100%;
        }
    }

    .fileList-btn {
        display: inline-block;
        padding: 0 15px;
        background-color: red;
        font-size: 12px;
        color: white;
        cursor: pointer;
        border-radius: 22px;
        height: 30px;
        line-height: 30px;
        margin: 0;
    }

    .fileList-btns {
        margin-right: 10px;
    }

    .winColor {
        color: blue;
    }

    .elBtn {
        width: 99px;
        height: 36px;
        background: #FFAA30;
        border-radius: 16px;
        border: none;
    }
    .afresh{
        width: 99px;
        height: 36px;
        background: #0DB8FF;
        border-radius: 16px;
        border:none;
        margin-right: 13px;
    }
    .el-dialog__body{
        display: flex;
        justify-content: center;
    }
    .cell{
        text-align: center;
    }
</style>
